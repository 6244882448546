import compact from 'lodash/compact'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player/file'
import Fade from 'react-reveal/Fade'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, LinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'

const VideoHero = ({
  style,
  contentStyle,
  videoUrl,
  title,
  description,
  theme,
  callToAction,
  useCarousel,
}) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const carouselRef = useRef()
  const slideItems =
    useCarousel &&
    useMemo(
      () =>
        compact(
          description.childMarkdownRemark.html
            .split('<p>')
            .map((item) => item.replace('</p>', '').trim())
        ),
      [description]
    )

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveSlide((activeSlide + 1) % slideItems.length)
    }, 5000)
    return () => clearInterval(timer)
  }, [slideItems, activeSlide])

  return (
    <div
      css={[
        css`
          ${tw`relative h-hero-video-mobile lg:h-hero-video-default`}
          strong {
            ${tw`font-medium`}
          }
          @media (min-width: 1681px) {
            height: 57.5rem;
          }
        `,
        style,
      ]}
    >
      <div
        css={[
          css`
            ${tw`absolute! -top-20 bottom-0 left-0 right-0 w-full bg-black`}
            height: calc(100% + 5rem);
            video {
              ${tw`object-cover opacity-40`}
            }
          `,
        ]}
      >
        <ReactPlayer url={videoUrl} width="100%" height="100%" playing loop muted playsinline />
      </div>
      <div css={[global`layout.container`, global`layout.grid`, tw`absolute inset-0`]}>
        <div css={[tw`flex flex-col items-start col-span-8`, contentStyle]}>
          <Fade bottom delay={500}>
            <Heading
              headingType="h1"
              content={title}
              style={[
                css`
                  p {
                    ${tw`font-light text-white`}
                  }
                  span {
                    ${tw`font-medium`}
                  }
                `,
                tw`mt-20 lg:mt-40 text-primary-500`,
              ]}
            />
          </Fade>
          <Fade bottom delay={1000}>
            {useCarousel ? (
              <div ref={carouselRef} css={tw`relative flex flex-row overflow-hidden`}>
                {(slideItems || []).map((slide, i) => (
                  <div
                    key={i}
                    css={[
                      tw`relative flex-none w-full transition-all duration-300 ease-in-out opacity-0`,
                      css`
                        left: calc(${i} * -100%);
                      `,
                      activeSlide === i && tw`opacity-100`,
                    ]}
                  >
                    <Heading
                      headingType="h4"
                      content={slide}
                      style={[
                        theme === 'navy' ? tw`text-primary-500` : tw`text-white`,
                        tw`mt-12 font-normal`,
                      ]}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <Heading
                headingType="h4"
                content={description}
                style={[
                  theme === 'navy' ? tw`text-primary-500` : tw`text-white`,
                  tw`mt-12 font-normal`,
                ]}
              />
            )}
          </Fade>
          <Fade bottom delay={1500}>
            {callToAction && (
              <Button
                type="primary"
                size="lg"
                theme="cyan-navy"
                label={callToAction.label}
                link={callToAction.link}
                style={tw`mt-6 lg:mt-12`}
                hideCaret={false}
              />
            )}
          </Fade>
        </div>
      </div>
    </div>
  )
}

VideoHero.propTypes = {
  style: StyleType,
  contentStyle: StyleType,
  videoUrl: PropTypes.string,
  title: HTMLType,
  description: HTMLType,
  callToAction: LinkType,
  theme: PropTypes.oneOf(['white', 'navy']),
  useCarousel: PropTypes.bool,
}

export default VideoHero
