import first from 'lodash/first'
import isFinite from 'lodash/isFinite'
import isNumber from 'lodash/isNumber'
import last from 'lodash/last'
import toNumber from 'lodash/toNumber'
import PropTypes from 'prop-types'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import tw from 'twin.macro'
import { CountUp } from 'use-count-up'
import { HTMLType } from '../utils/prop-types'
import Heading from './heading'
import Text from './text'

const Statistic = ({ label, value }) => {
  const { ref, entry } = useInView({ threshold: [0.25, 0.75] })
  const valueChildren = value.split(' ')
  const content = valueChildren.map((child, i) => {
    const matches = (child.match(/([\d.]+)/g) || []).filter(
      (v) => isNumber(toNumber(v)) && isFinite(toNumber(v))
    )
    if (matches.length > 0) {
      const number = first(matches)
      const prefix = first(child.split(number))
      const suffix = last(child.split(number))
      return (
        <CountUp
          key={i}
          isCounting={entry && entry.isIntersecting}
          end={toNumber(number)}
          duration={5}
          suffix={suffix}
          prefix={prefix}
        />
      )
    }
    return `${i !== 0 ? ' ' : ''}${child}${i !== valueChildren.length - 1 ? ' ' : ''}`
  })

  return (
    <div
      css={tw`flex flex-col py-8 first-of-type:pt-0 last-of-type:pb-0
      lg:(items-center flex-equal py-0)`}
      ref={ref}
    >
      <Heading
        content={content}
        headingType="h2"
        style={tw`leading-none text-secondary-500 lg:text-center`}
      />
      <Text content={label} style={tw`mt-4 text-white lg:text-center`} />
    </div>
  )
}

Statistic.propTypes = {
  label: HTMLType,
  value: PropTypes.string,
}

const Statistics = ({ statistics }) => {
  return (
    <div
      css={tw`flex flex-col divide-y divide-primary-400
      lg:(flex-row justify-center space-y-0 space-x-8 divide-y-0)`}
    >
      {statistics.map((statistic, i) => (
        <Statistic key={i} label={statistic.labelNode} value={statistic.value} />
      ))}
    </div>
  )
}

Statistics.propTypes = {
  statistics: PropTypes.arrayOf(PropTypes.object),
}

export default Statistics
