import Lottie from 'lottie-web'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { StyleType } from '../utils/prop-types'

const HeroAnimation = ({ animationData, style }) => {
  const heroDiv = useRef()

  const loadAnimation = (json) => {
    return Lottie.loadAnimation({
      container: heroDiv.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: json,
      rendererSettings: {
        preserveAspectRatio: 'xMinYMax meet',
      },
    })
  }

  useEffect(() => {
    const animation = loadAnimation(animationData) // change here by props
    return () => {
      animation.destroy()
    }
  }, [])

  return <div ref={heroDiv} css={style} />
}

HeroAnimation.propTypes = {
  animationData: PropTypes.object,
  style: StyleType,
}

export default HeroAnimation
