import React from 'react'
import Overlay from './overlay'
import Rectangle from './rectangle'

export const overlays = {
  hero: (
    <>
      <Overlay visibility="desktop" position="bottom-left" width={28} height={184}>
        <Rectangle x={0} y={0} width={28} height={28} fill="white" />
      </Overlay>
      <Overlay visibility="desktop" position="right" width={646} height={364}>
        <Rectangle x={0} y={84} width={28} height={28} fill="cyan" />
        <Rectangle x={478} y={280} width={168} height={28} fill="yellow" />
        <Rectangle x={590} y={308} width={56} height={56} fill="navy" />
      </Overlay>
    </>
  ),
}
